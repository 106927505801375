<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <!--안전보건 내부심사 시정조치 목록-->
    <c-table
      ref="table"
      title="LBL0010153"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :editable="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-improvement',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'saiInternalActionId' },
          { index: 1, colName: 'deptCd' },
          // { index: 2, colName: 'saiInternalAuditClassNameDeptGubun' },
          { index: 2, colName: 'systemElementsNameGubun' },
          { index: 3, colName: 'saiInternalAuditChecklistId' },
          { index: 4, colName: 'saiInternalAuditChecklistId' },
          { index: 5, colName: 'saiInternalAuditChecklistId' },
          { index: 6, colName: 'saiInternalAuditChecklistId' },
          // { index: 7, colName: 'saiInternalAuditChecklistId' },
          // { index: 9, colName: 'saiInternalAuditChecklistId' },
          // { index: 10, colName: 'saiInternalAuditChecklistId' },
        ],
        columns: [
          {
            name: 'actionName',
            field: 'actionName',
            //수행계획서명
            label: 'LBL0010116',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            //피심사<br/>부서
            label: 'LBL0010133',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          // {
          //   name: 'saiInternalAuditClassName',
          //   field: 'saiInternalAuditClassName',
          //   // ISO 구분
          //   label: 'ISO 구분',
          //   align: 'center',
          //   style: 'width:80px',
          //   sortable: false,
          // },
          {
            name: 'systemElementsName',
            field: 'systemElementsName',
            label: '관련표준',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          // {
          //   name: 'sortOrder',
          //   field: 'sortOrder',
          //   //순서(순번)
          //   label: 'LBLSORTORDER',
          //   align: 'center',
          //   style: 'width:30px',
          //   sortable: false,
          // },
          {
            name: 'itemName',
            field: 'itemName',
            //내부심사 항목
            label: '심사항목',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            //내부심사 시<br/>착안사항
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'saiInternalAuditResultName',
            field: 'saiInternalAuditResultName',
            //내부<br/>심사<br/>결과
            label: 'LBL0010141',
            align: 'center',
            style: 'width:50px',
            sortable: false,
          },
          // {
          //   name: 'result',
          //   field: 'result',
          //   label: '내부심사결과',
          //   child: [
          //     {
          //       name: 'sar0000001',
          //       field: 'sar0000001',
          //       label: '양호',
          //       align: 'center',
          //       style: 'width:40px',
          //       sortable: false,
          //       type: 'check',
          //       checkIcon: 'task_alt',
          //       uncheckIcon: 'radio_button_unchecked',
          //       'true': 'Y',
          //       'false': 'N',
          //       color: 'green', 
          //     },
          //     {
          //       name: 'sar0000005',
          //       field: 'sar0000005',
          //       label: '부적합',
          //       align: 'center',
          //       style: 'width:40px',
          //       sortable: false,
          //       type: 'check',
          //       checkIcon: 'task_alt',
          //       uncheckIcon: 'radio_button_unchecked',
          //       'true': 'Y',
          //       'false': 'N',
          //       color: 'green', 
          //     },
          //     {
          //       name: 'sar0000010',
          //       field: 'sar0000010',
          //       label: '관찰사항',
          //       align: 'center',
          //       style: 'width:40px',
          //       sortable: false,
          //       type: 'check',
          //       checkIcon: 'task_alt',
          //       uncheckIcon: 'radio_button_unchecked',
          //       'true': 'Y',
          //       'false': 'N',
          //       color: 'green', 
          //     },
          //     {
          //       name: 'sar0000015',
          //       field: 'sar0000015',
          //       label: '비적용',
          //       align: 'center',
          //       style: 'width:40px',
          //       sortable: false,
          //       type: 'check',
          //       checkIcon: 'task_alt',
          //       uncheckIcon: 'radio_button_unchecked',
          //       'true': 'Y',
          //       'false': 'N',
          //       color: 'green', 
          //     },
          //   ]
          // },
          {
            name: 'observation',
            field: 'observation',
            //관찰내용<br/>(부적합사항<br/>및 보고서No.)
            label: '관찰내용',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            //개선요청 제목
            label: 'LBL0010154',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            //개선구분
            label: 'LBL0010155',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            //진행상태
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:80px',
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            //요청부서정보
            label: 'LBL0010156',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            //조치<br/>부서
            label: 'LBL0010157',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.internal.action.checklistResult.impr.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      // this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = 'LBLIMPROVE';   //개선
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
